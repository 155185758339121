import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
//import BackgroundImage from "gatsby-background-image"
//import { getImage } from "gatsby-plugin-image"
//import { convertToBgImage } from "gbimage-bridge"

import "./hero.css"

const Banner = () => {
  //const { heroImage } = useStaticQuery(
  //  graphql`
  //    {
  //      heroImage: file(
  //        relativePath: { eq: "new-images/quickbase-banner-test.png" }
  //      ) {
  //        childImageSharp {
  //          gatsbyImageData(
  //            width: 1920
  //            layout: CONSTRAINED
  //            formats: [AUTO, WEBP, AVIF]
  //          )
  //        }
  //      }
  //    }
  //  `
  //)

  //const heroImageData = getImage(heroImage)

  // Use like this:
  //const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      {/*<BackgroundImage
        Tag="div"
        {...bgImage}
        // fluid={imageData}
      >*/}
      <div className="hero-whitefade-quickbase-security-banner">
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-6 mr-auto">
              <div className="hero-custom-content-home">
                <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                  APPLICATION SECURITY AUDIT
                </h5>
                <h1 className="page-title-quickbase font-weight-medium mb-4">
                  How Secure Are Your Quickbase Applications?
                </h1>
                <p className="text-black">
                  From small issues to potentially catastrophic failures, we
                  help you find and prioritize your security vulnerabilities
                  with our Quickbase security audit.
                </p>
                <div className="mt-4">
                  <Link
                    aria-label="link"
                    className="font-weight-medium"
                    to="/contact/"
                  >
                    Book Your Security Consultation
                    <i className="ml-2 item-arrow" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 p-sm-5"
              style={{ position: "relative" }}
            ></div>
          </div>
        </div>
        {/*</BackgroundImage>*/}
      </div>
    </>
  )
}

export default Banner
